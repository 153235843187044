// 'use client';
// import { clearAlertPopup } from '@/store/alertSlice';
// import { Snackbar } from '@mui/material';
// import MuiAlert from '@mui/material/Alert';
// import * as React from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const AlertSnackbar = () => {
//   const dispatch = useDispatch();
//   const { message, type, duration, showSnackbar } = useSelector(
//     state => state.alertpopup
//   );

//   // useEffect(() => {
//   //   const handleRouteChange = () => {
//   //     dispatch(clearAlertPopup());
//   //   };

//   //   router.events.on('routeChangeStart', handleRouteChange);

//   //   return () => {
//   //     router.events.off('routeChangeStart', handleRouteChange);
//   //   };
//   // }, [dispatch, router]);

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     dispatch(clearAlertPopup());
//   };

//   return (
//     <Snackbar
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//       open={showSnackbar}
//       autoHideDuration={duration}
//       onClose={handleClose}
//       message={message}
//       severity={type}
//     >
//       <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
//         {message}
//       </Alert>
//     </Snackbar>
//   );
// };

// export default AlertSnackbar;

'use client';

import { clearAlertPopup } from '@/store/alertSlice';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = () => {
  const dispatch = useDispatch();
  const { message, type, duration, showSnackbar } = useSelector(
    state => state.alertpopup
  );

  useEffect(() => {
    let timerId;
    if (showSnackbar && duration > 0) {
      timerId = setTimeout(() => {
        dispatch(clearAlertPopup());
      }, duration);
    }

    // Cleanup function to clear the timeout if the component unmounts
    // or if the duration/showSnackbar changes before the timer is up.
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [dispatch, showSnackbar, duration]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearAlertPopup());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={showSnackbar}
      autoHideDuration={duration}
      onClose={handleClose}
      sx={{
        zIndex: 9007199254740991, // Snackbar has a higher z-index
      }} // Set the zIndex here
      //sx={{ zIndex: 9007199254740991 }} // Set the zIndex here
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
